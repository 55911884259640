<template>
  <Component :is="computedComponent" :data="computedProps" />
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue';

const props = defineProps({
  blockType: {
    type: String,
    default: '',
  },
  data: {
    type: [Object, String],
    default: () => ({}),
  },
});

const computedComponent = computed(() => {
  const mapping = {
    hero: defineAsyncComponent(() => import('./blocks/BlockSplash.vue')),
    h1: defineAsyncComponent(() => import('./blocks/BlockTitle.vue')),
    title: defineAsyncComponent(() => import('./blocks/BlockTitle.vue')),
    h2: defineAsyncComponent(() => import('./blocks/BlockTitle.vue')),
    h3: defineAsyncComponent(() => import('./blocks/BlockTitle.vue')),
    image: defineAsyncComponent(() => import('./blocks/BlockImage.vue')),
    paragraph: defineAsyncComponent(() => import('./blocks/BlockParagraph.vue')),
    picture_accordion: defineAsyncComponent(() => import('./blocks/BlockPictureAccordion.vue')),
    feature_cards: defineAsyncComponent(() => import('./blocks/BlockBenefitsCards.vue')),
    car_catalog: defineAsyncComponent(() => import('./blocks/BlockCarCatalogClient.vue')),
    car_banner: defineAsyncComponent(() => import('./blocks/BlockCarBanner.vue')),
    picture_banner: defineAsyncComponent(() => import('./blocks/BlockBannerImage.vue')),
    testimonials_slider: defineAsyncComponent(() => import('./blocks/BlockTestimonialsSlider.vue')),
    brands_slider: defineAsyncComponent(() => import('./blocks/BlockBrandsSlider.vue')),
    icon_cards: defineAsyncComponent(() => import('./blocks/BlockIconCards.vue')),
    faq: defineAsyncComponent(() => import('./blocks/BlockFaqs.vue')),
    brands_grid: defineAsyncComponent(() => import('./blocks/BlockBrandsGrid.vue')),
    car_types_grid: defineAsyncComponent(() => import('./blocks/BlockCarTypesGrid.vue')),
    marquee: defineAsyncComponent(() => import('./blocks/BlockMarquee.vue')),
    contact_form: defineAsyncComponent(() => import('./blocks/BlockContactForm.vue')),
    blog_articles_grid: defineAsyncComponent(() => import('./blocks/BlockBlogArticlesGrid.vue')),
    vehicle_catalog: defineAsyncComponent(() => import('./blocks/BlockVehiclesCatalog.vue')),
  };

  if (props.data.enabled === false) {
    return undefined;
  }

  return mapping[props.blockType];
});

const isTitleTag = /^h[1-3]$/;
const computedProps = computed(() => {
  if (isTitleTag.test(props.blockType)) {
    return {
      ...props.data,
      level: props.blockType,
    };
  }

  return props.data;
});
</script>
